module.exports.SERVER_RESPONSE_CODE = {

	// old server response code mapping to new http server response code above
	SUCCESS: 0,
	ERROR: 1,
	NOT_EXIST: 2,
	ALREADY_EXIST: 3,
	SERVER_LOCKED: 4,
	SERVER_NOT_READY: 5,
	INVALID_SESSION: 6,

	// http server response code
	HTTP_SUCCESS: 200,
	HTTP_ERROR: 500,
	HTTP_PAGE_NOT_FOUND: 404,

	// clan code
	CLAN_CODE,
	
}

module.exports.CLAN_MEMBER_ROLE = {

	// server reponse code
	UNKNOWN: 0,
    MEMBER: 1,
    DEPUTY: 2,
    LEADER: 3,
}

var CLAN_CODE = module.exports.CLAN_CODE = {
	// clan response code
	CLAN_INPUT_MISSING: 1001,
	CLAN_EXISTING_FROM_CREATOR: 1002,

	// full member or not enough base power or is member clan but request apply
	CLAN_ERROR_APPLY_CANCEL_JOIN_CLAN: 1003,
	CLAN_ERROR_ACCEPT_INVITE_JOIN_CLAN: 1004,
	CLAN_ERROR_CONNECT_USER_PROFILE: 1005,
	CLAN_ERROR_CHANGE_CLAN_INFO: 1006,
	CLAN_ERROR_CREATE_NEW_CLAN: 1007,
	CLAN_ERROR_CLAN_IS_NOT_EXIST: 1008,
	CLAN_ERROR_LEADER_CANNOT_LEAVE_CLAN: 1009,
	CLAN_ERROR_CLAN_IS_NOT_EXIST_MEMBER: 1010,
	CLAN_ERROR_CLAN_IS_FULL_DEPUTY: 1011,
	CLAN_ERROR_CLAN_IS_NOT_DEPUTY: 1012,
	CLAN_ERROR_CLAN_IS_NOT_LEADER: 1013,
	CLAN_ERROR_CLAN_IS_FULL_MEMBER: 1014,
	CLAN_ERROR_CLAN_DELETE: 1015,
	CLAN_ERROR_CLAN_NOT_ENOUGH_POWER: 1016,
	CLAN_ERROR_CLAN_USER_EXIST_ANOTHER: 1017,
	CLAN_ERROR_USER_EXIST_INVITED: 1018,
	CLAN_ERROR_USER_NOT_EXIST_INVITED: 1019,
	CLAN_ERROR_USER_EXIST_APPLIED: 1020,
	CLAN_ERROR_USER_NOT_EXIST_APPLIED: 1021,
	CLAN_ERROR_USER_IS_MEMBER: 1022,
	CLAN_ERROR_USER_APPLY_FULL: 1023,
	CLAN_ERROR_USER_IS_DEPUTY: 1024,
	CLAN_ERROR_NOT_RESULT_MATCH: 1025,
	CLAN_ERROR_NOT_PERMISSION: 1026,
	CLAN_ERROR_MAX_LEVEL: 1027,
	CLAN_ERROR_MAX_DONATE_DAILY: 1028,
	CLAN_ERROR_NOT_EXIST_BADGE_ID: 1029,
	CLAN_ERROR_EXIST_BADGE_ID: 1030,
	CLAN_ERROR_WAS_KICK_FROM_CLAN: 1031,
}


module.exports.CLAN_STATUS = {

	UNKNOWN: 0,
	NORMAL: 1,
	CHEAT_WARNING: 2,
	BLACK_LIST: 3,	
}

module.exports.USER_STATUS = {

	UNKNOWN: 0,
	NORMAL: 1,
	CHEAT_WARNING: 2,
	BLACK_LIST: 3,	
}