<template>
	<div class="app-container">
		<el-tabs
			v-model="activeTab"
			@tab-click="handleClick"
			v-loading="loading"
		>
			<el-tab-pane label="Search" name="search">
				<el-row :gutter="20">
					<el-col :span="6">
						<el-input
							v-model="searchString"
							placeholder="Please input string"
						></el-input>
					</el-col>
					<el-col :span="3">
						<el-select
							v-model="currentSearchOption"
							placeholder="Select"
						>
							<el-option
								v-for="item in searchOptions"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							>
							</el-option>
						</el-select>
					</el-col>
					<el-col :span="2">
						<el-button
							type="primary"
							icon="el-icon-search"
							@click="searchClan()"
							>Search</el-button
						>
					</el-col>
				</el-row>

				<br />

				<el-table
					:data="this.searchClanList"
					border
					max-height="700"
					highlight-current-row
					@current-change="handleCurrentTableChange"
					style="width: 100%"
				>
					<el-table-column prop="clanID" label="Clan ID" width="250">
					</el-table-column>
					<el-table-column
						prop="clanName"
						label="Clan Name"
						width="250"
					>
					</el-table-column>
					<el-table-column prop="leaderUserID" label="Leader ID">
					</el-table-column>
					<el-table-column
						prop="totalMemberBasePower"
						label="Total Power"
					>
					</el-table-column>
					<el-table-column prop="statusName" label="Status">
					</el-table-column>
				</el-table>
			</el-tab-pane>

			<el-tab-pane label="Clan List" name="clanlist">
				<div>Total Clan: {{ totalClan }}</div>
				<br />
				<div>
					<button
						@click="changeTab(currentPage - 1)"
						:disabled="currentPage <= 1"
					>
						Previous
					</button>
					[{{ currentPage }} / {{ totalPages }}]
					<button
						@click="changeTab(currentPage + 1)"
						:disabled="currentPage >= totalPages"
					>
						Next
					</button>
				</div>

				<br />

				<el-table
					:data="clanList"
					border
					max-height="700"
					highlight-current-row
					@current-change="handleCurrentTableChange"
					style="width: 100%"
				>
					<el-table-column label="Index" type="index" width="60" />
					<el-table-column
						prop="clanID"
						label="Clan ID"
						width="250"
					/>
					<el-table-column
						prop="clanName"
						label="Clan Name"
						width="250"
					>
					</el-table-column>
					<el-table-column prop="leaderUserID" label="Leader ID" />
					<el-table-column
						prop="totalMemberBasePower"
						label="Total Power"
						sortable
					>
					</el-table-column>
					<el-table-column
						prop="statusName"
						label="Status"
						sortable
					/>
				</el-table>
			</el-tab-pane>

			<el-tab-pane label="Clan Info" name="claninfo">
				<el-collapse v-model="activeCollapseName">
					<el-collapse-item name="claninfo">
						<template slot="title">
							<h2>Clan Information</h2>
						</template>

						<el-card class="box-card">
							<el-form
								label-width="200px"
								:model="currentClanInfo"
							>
								<el-form-item label="Clan ID:">
									<el-input
										v-model="currentClanInfo.clanID"
										style="width: 300px"
										disabled
									>
									</el-input>
								</el-form-item>
								<el-form-item label="Leader ID:">
									<el-input
										v-model="currentClanInfo.leaderUserID"
										style="width: 300px"
										disabled
									>
									</el-input>
								</el-form-item>

								<el-form-item label="Status:">
									<el-input
										v-model="currentClanInfo.statusName"
										style="width: 300px"
										disabled
									>
									</el-input>
								</el-form-item>

								<el-form-item label="Deputies:">
									<el-select
										v-model="selectedDeputyID"
										placeholder="Deputy ID"
									>
										<el-option
											v-for="item in deputiesMemberList"
											:key="item.value"
											:label="item.label"
											:value="item.value"
										>
										</el-option>
									</el-select>
								</el-form-item>

								<el-form-item label="Clan Name:">
									<el-input
										v-model="currentClanInfo.clanName"
										style="width: 300px"
									>
									</el-input>
								</el-form-item>

								<el-form-item label="Clan Level:">
									<el-input-number
										v-model="currentClanInfo.clanLevel"
										style="width: 300px"
									>
									</el-input-number>
								</el-form-item>

								<el-form-item label="Max Member:">
									<el-input-number
										v-model="currentClanInfo.maxMember"
										style="width: 300px"
									>
									</el-input-number>
								</el-form-item>
								<el-form-item label="Region ID:">
									<el-input-number
										v-model="currentClanInfo.regionID"
										style="width: 300px"
									>
									</el-input-number>
								</el-form-item>
								<el-form-item label="Badge ID:">
									<el-input-number
										v-model="currentClanInfo.badgeID"
										style="width: 300px"
									>
									</el-input-number>
								</el-form-item>
								<el-form-item label="Clan Note:">
									<el-input
										type="textarea"
										:rows="2"
										v-model="currentClanInfo.clanNote"
										style="width: 300px"
									>
									</el-input>
								</el-form-item>
							</el-form>

							<el-button type="primary" @click="updateClanInfo()"
								>Update</el-button
							>
							<el-button type="primary" @click="resetClanInfo()"
								>Reset</el-button
							>
						</el-card>
					</el-collapse-item>

					<el-collapse-item name="2">
						<template slot="title">
							<h2>Set Clan Status</h2>
						</template>
						<el-card class="box-card">
							<el-form label-width="200px">
								<el-form-item label="Status: ">
									<el-col :span="4">
										<el-select
											v-model="currentStatusOption"
											placeholder="Select Status"
										>
											<el-option
												v-for="item in statusOptions"
												:key="item.value"
												:label="item.label"
												:value="item.value"
											>
											</el-option>
										</el-select>
									</el-col>

									<el-col :span="2">
										<el-button
											type="primary"
											@click="changeClanStatus()"
										>
											Change Status
										</el-button>
									</el-col>
								</el-form-item>
							</el-form>
						</el-card>
					</el-collapse-item>

					<el-collapse-item name="3">
						<template slot="title">
							<h2>Set Clan Leader From Deputy</h2>
						</template>
						<el-card class="box-card">
							<el-form label-width="200px">
								<el-form-item label="Deputies:">
									<el-col :span="4">
										<el-select
											v-model="selectedDeputyID"
											placeholder="Deputy ID"
										>
											<el-option
												v-for="item in deputiesMemberList"
												:key="item.value"
												:label="item.label"
												:value="item.value"
											>
											</el-option>
										</el-select>
									</el-col>

									<el-col :span="2">
										<el-button
											type="primary"
											@click="changeClanLeader()"
										>
											Change Leader
										</el-button>
									</el-col>
								</el-form-item>
							</el-form>
						</el-card>
					</el-collapse-item>

					<el-collapse-item name="4">
						<template slot="title">
							<h2>Others: Set Deputy, Kick Member, Send Inbox</h2>
						</template>

						<el-card class="box-card">
							<el-form label-width="200px">
								<el-form-item label="Members:">
									<el-select
										v-model="selectedMemberID"
										placeholder="Member ID"
										style="width: 400px"
									>
										<el-option
											v-for="item in memberList"
											:key="item.value"
											:label="item.label"
											:value="item.value"
											style="width: 400px"
										>
										</el-option>
									</el-select>
								</el-form-item>

								<el-col :span="2">
									<el-button
										type="primary"
										@click="setDeputy()"
									>
										Set Deputy
									</el-button>
								</el-col>

								<el-col :span="2">
									<el-button
										type="primary"
										@click="kickMember()"
									>
										Kick Member
									</el-button>
								</el-col>

								<el-col :span="2">
									<el-button
										type="primary"
										@click="sendInbox()"
									>
										Send Inbox
									</el-button>
								</el-col>
							</el-form>
						</el-card>
					</el-collapse-item>
				</el-collapse>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import { Message, MessageBox } from "element-ui";
import apiClan from "@/api/clan.js";
import { SERVER_RESPONSE_CODE, CLAN_STATUS } from "@/defines/constants.js";
import mtoUserProfile from "@/api/mtoUserProfile";

export default {
	name: "ClanInformation",
	data() {
		return {
			loading: false,
			activeCollapseName: ["claninfo"],
			activeTab: "search",
			totalRows: 100,
			currentPage: 1,
			totalPages: 0,
			totalClan: 0,
			selectedDeputyID: "",
			selectedMemberID: "",
			lastSelectedClanInfo: {},
			currentClanInfo: {},
			memberList: [],
			deputiesMemberList: [],
			clanList: [],
			searchClanList: [],
			searchString: "",
			currentSearchOption: "",
			searchOptions: [
				{
					value: "clanName",
					label: "Clan Name",
				},
				{
					value: "clanID",
					label: "Clan ID",
				},
				{
					value: "regionID",
					label: "Region ID",
				},
				{
					value: "clanLevel",
					label: "Clan Level",
				},
			],
			currentStatusOption: 0,
			statusOptions: [
				{
					value: 0,
					label: "UNKNOWN",
				},
				{
					value: 1,
					label: "NORMAL",
				},
				{
					value: 2,
					label: "CHEAT_WARNING",
				},
				{
					value: 3,
					label: "BLACK_LIST",
				},
			],
		};
	},
	created() {
		this.loading = false;
		this.totalRows = 100;
		this.currentPage = 1;
		this.totalPages = 0;
		this.totalClan = 0;
		this.selectedDeputyID = "";
		this.selectedMemberID = "";
		this.lastSelectedClanInfo = {};
		this.currentClanInfo = {};
		this.memberList = [];
		this.clanList = [];
		this.searchClanList = [];
		this.clanMemberStatus = {};
		//this.getClanInfoListByPage();
	},
	methods: {
		// ========================================
		async getClanInfoListByPage() {
			this.loading = true;

			let responseData = await apiClan.getTotalClan();

			if (responseData.code == SERVER_RESPONSE_CODE.SUCCESS) {
				this.totalClan = responseData.data;
				this.totalPages = Math.ceil(this.totalClan / this.totalRows);
			}

			responseData = await apiClan.getClanInfoListByPage(
				this.totalRows,
				this.currentPage
			);

			this.loading = false;

			if (responseData.code == SERVER_RESPONSE_CODE.SUCCESS) {
				this.clanList = responseData.data;
				this.clanList.forEach((clanInfo) => {
					if (clanInfo.status === null || clanInfo.status === undefined) {
						clanInfo.status = CLAN_STATUS.UNKNOWN;
					}

					clanInfo.statusName = this.getKeyByValue(
						CLAN_STATUS,
						clanInfo.status
					);
				});
			} else {
				console.log(responseData);
			}
		},

		// ========================================
		async searchClan() {
			if (this.searchString.length <= 0) {
				Message({
					message: `Please input "Search String"`,
					type: "error",
					duration: 5 * 1000,
				});
				return;
			}

			if (this.searchString.length <= 3) {
				Message({
					message: "Atleast 3 characters",
					type: "error",
					duration: 5 * 1000,
				});
				return;
			}

			if (this.currentSearchOption.length <= 0) {
				Message({
					message: `Please select "Search" Options`,
					type: "error",
					duration: 5 * 1000,
				});
				return;
			}

			this.searchClanList = [];
			let responseData = undefined;

			if (this.currentSearchOption == "clanName") {
				responseData = await apiClan.searchClanByClanName(this.searchString);
			}

			if (this.currentSearchOption == "clanID") {
				responseData = await apiClan.searchClanByClanID(this.searchString);
			}

			if (this.currentSearchOption == "regionID") {
				responseData = await apiClan.searchClanByRegionID(this.searchString);
			}

			if (this.currentSearchOption == "clanLevel") {
				responseData = await apiClan.searchClanByClanLevel(this.searchString);
			}

			if (responseData != undefined) {
				if (responseData.code == SERVER_RESPONSE_CODE.SUCCESS) {
					this.searchClanList = responseData.data;
					Message({
						message: `Clan founds: ${this.searchClanList.length}`,
						type: "success",
						duration: 5 * 1000,
					});

					this.searchClanList.forEach((clanInfo) => {
						if (clanInfo.status === null || clanInfo.status === undefined) {
							clanInfo.status = CLAN_STATUS.UNKNOWN;
						}

						clanInfo.statusName = this.getKeyByValue(
							CLAN_STATUS,
							clanInfo.status
						);
					});

					console.log(this.searchClanList);
				} else {
					console.log(responseData);
				}
			}
		},

		// ========================================
		async updateClanInfo() {
			if (
				this.currentClanInfo.clanID === this.lastSelectedClanInfo.clanID &&
				this.currentClanInfo.clanName === this.lastSelectedClanInfo.clanName &&
				this.currentClanInfo.clanLevel ===
				this.lastSelectedClanInfo.clanLevel &&
				this.currentClanInfo.maxMember ===
				this.lastSelectedClanInfo.maxMember &&
				this.currentClanInfo.regionID === this.lastSelectedClanInfo.regionID &&
				this.currentClanInfo.badgeID === this.lastSelectedClanInfo.badgeID &&
				this.currentClanInfo.clanNote === this.lastSelectedClanInfo.clanNote &&
				this.currentClanInfo.leaderUserID ===
				this.lastSelectedClanInfo.leaderUserID
			) {
				Message({
					message: `There is not any change!`,
					type: "error",
					duration: 5 * 1000,
				});
				return;
			} else {
				try {
					let responseData = await apiClan.updateClanInfo(this.currentClanInfo);
					if (responseData.code == SERVER_RESPONSE_CODE.SUCCESS) {
						this.currentClanInfo = responseData.data;
						this.lastSelectedClanInfo = { ...this.currentClanInfo };
						this.memberList = this.getMemberListFromDict(this.currentClanInfo);

						Message({
							message: `Update Clan Info successfully!`,
							type: "success",
							duration: 5 * 1000,
						});
					} else {
						console.log(responseData);
					}
				} catch (error) {
					console.log(error);
				}
			}
		},

		// ========================================
		async changeClanStatus() {
			if (this.currentStatusOption === this.currentClanInfo.status) {
				Message({
					message: `Please selected a Status to change!`,
					type: "error",
					duration: 5 * 1000,
				});

				return;
			}

			let statusName = this.getKeyByValue(
				CLAN_STATUS,
				this.currentStatusOption
			);
			let tempConfirm = undefined;
			try {
				tempConfirm = await MessageBox.confirm(
					`Do you confirm to change status :  ${statusName}?`,
					"Confirm set",
					{
						confirmButtonText: "Change",
						cancelButtonText: "Cancel",
						type: "warning",
					}
				);
			} catch (error) {
				console.log(error);
				return;
			}

			if (tempConfirm != "confirm") {
				return;
			}

			try {
				let responseData = await apiClan.setClanStatus(
					this.currentClanInfo.clanID,
					statusName
				);

				if (responseData.code == SERVER_RESPONSE_CODE.SUCCESS) {
					this.setClanInfoFromServer(responseData.data);
					this.lastSelectedClanInfo = { ...this.currentClanInfo };
					this.memberList = this.getMemberListFromDict(this.currentClanInfo);

					Message({
						message: `Set Status ${statusName} successfully!`,
						type: "success",
						duration: 5 * 1000,
					});
				} else {
					console.log(responseData);
				}
			} catch (error) {
				console.log(error);
			}
		},

		// ========================================
		async changeClanLeader() {
			if (
				this.selectedDeputyID === undefined ||
				this.selectedDeputyID.length <= 0
			) {
				Message({
					message: `Please selected a Deputy to change!`,
					type: "error",
					duration: 5 * 1000,
				});

				return;
			}

			let tempConfirm = undefined;
			try {
				tempConfirm = await MessageBox.confirm(
					`Do you confirm to change deputy :  ${this.selectedDeputyID} to Clan Leader?`,
					"Confirm set",
					{
						confirmButtonText: "Set",
						cancelButtonText: "Cancel",
						type: "warning",
					}
				);
			} catch (error) {
				console.log(error);
				return;
			}

			if (tempConfirm != "confirm") {
				return;
			}

			try {
				let responseData = await apiClan.setClanLeaderFromDeputy(
					this.currentClanInfo.clanID,
					this.currentClanInfo.leaderUserID,
					this.selectedDeputyID
				);

				if (responseData.code == SERVER_RESPONSE_CODE.SUCCESS) {
					this.setClanInfoFromServer(responseData.data);
					this.lastSelectedClanInfo = { ...this.currentClanInfo };
					this.memberList = this.getMemberListFromDict(this.currentClanInfo);

					Message({
						message: `Set Deputy ${this.selectedDeputyID} to Clan Leader successfully!`,
						type: "success",
						duration: 5 * 1000,
					});
				} else {
					console.log(responseData);
				}
			} catch (error) {
				console.log(error);
			}
		},

		// ========================================
		async setDeputy() {
			if (
				this.selectedMemberID === undefined ||
				this.selectedMemberID.length <= 0
			) {
				Message({
					message: `Please selected a Member to change!`,
					type: "error",
					duration: 5 * 1000,
				});

				return;
			}

			if (this.currentClanInfo.leaderUserID === this.selectedMemberID) {
				Message({
					message: `Can't set Deputy from Clan Leader!`,
					type: "error",
					duration: 5 * 1000,
				});

				return;
			}

			if (
				this.currentClanInfo.listDeputyUserID.indexOf(this.selectedMemberID) !==
				-1
			) {
				Message({
					message: `Can't set Deputy from Clan Deputy!`,
					type: "error",
					duration: 5 * 1000,
				});

				return;
			}

			let tempConfirm = undefined;
			try {
				tempConfirm = await MessageBox.confirm(
					`Do you confirm to set member : ${this.selectedMemberID} to Deputy?`,
					"Confirm set",
					{
						confirmButtonText: "Set",
						cancelButtonText: "Cancel",
						type: "warning",
					}
				);
			} catch (error) {
				console.log(error);
				return;
			}

			console.log(tempConfirm);

			if (tempConfirm != "confirm") {
				return;
			}

			try {
				let responseData = await apiClan.setClanDeputyFromMember(
				this.currentClanInfo.leaderUserID,
					this.currentClanInfo.clanID,
					this.selectedMemberID
				);

				if (responseData.code === SERVER_RESPONSE_CODE.SUCCESS) {
					this.setClanInfoFromServer(responseData.data);
					this.lastSelectedClanInfo = { ...this.currentClanInfo };
					this.settingMemberData();
					//this.memberList = this.getMemberListFromDict(this.currentClanInfo);

					Message({
						message: `Set member ${this.selectedMemberID} to Deputy successfully!`,
						type: "success",
						duration: 5 * 1000,
					});
				} else {
					console.log(responseData);
					Message({
					message: `Set member ${this.selectedMemberID} to Deputy Error ${responseData.message}!`,
					type: "success",
					duration: 5 * 1000,
					});
				}
			} catch (error) {
				console.log(error);
			}
		},

		// ========================================
		async kickMember() {
			if (
				this.selectedMemberID === undefined ||
				this.selectedMemberID.length <= 0
			) {
				Message({
					message: `Please select a Member to kick!`,
					type: "error",
					duration: 5 * 1000,
				});

				return;
			}

			// if (this.currentClanInfo.leaderUserID === this.selectedMemberID) {
			// 	Message({
			// 		message: `Can't kick Clan Leader!`,
			// 		type: "error",
			// 		duration: 5 * 1000,
			// 	});
      //
			// 	return;
			// }

			// if (
			// 	this.currentClanInfo.listDeputyUserID.indexOf(this.selectedMemberID) !==
			// 	-1
			// ) {
			// 	Message({
			// 		message: `Can't kick Deputy!`,
			// 		type: "error",
			// 		duration: 5 * 1000,
			// 	});
      //
			// 	return;
			// }

			let tempConfirm = undefined;
			try {
				tempConfirm = await MessageBox.confirm(
					`Do you confirm to kick memberID :  ${this.selectedMemberID}?`,
					"Confirm kick",
					{
						confirmButtonText: "Kick",
						cancelButtonText: "Cancel",
						type: "warning",
					}
				);
			} catch (error) {
				console.log(error);
				return;
			}

			if (tempConfirm != "confirm") {
				return;
			}

			try {
				let responseData = await apiClan.kickMember(
				this.currentClanInfo.leaderUserID,
					this.currentClanInfo.clanID,
					this.selectedMemberID
				);

				if (responseData.code === SERVER_RESPONSE_CODE.SUCCESS) {
					this.setClanInfoFromServer(responseData.data);
					this.lastSelectedClanInfo = { ...this.currentClanInfo };
					this.memberList = this.getMemberListFromDict(this.currentClanInfo);

					Message({
						message: `Kicked Member ${this.selectedMemberID} successfully!`,
						type: "success",
						duration: 5 * 1000,
					});
				} else {
					console.log(responseData);
					Message({
					message: `Kicked Member ${this.selectedMemberID} Error ${responseData.message}!`,
					type: "success",
					duration: 5 * 1000,
					});
				}
			} catch (error) {
				console.log(error);
			}
		},

		// ========================================
		sendInbox() {
			if (
				this.selectedMemberID === undefined ||
				this.selectedMemberID.length <= 0
			) {
				Message({
					message: `Please select a Member to send inbox!`,
					type: "error",
					duration: 5 * 1000,
				});

				return;
			}

			window.location.href = `/#/sendmail/${this.selectedMemberID}`;
		},

		// ========================================
		// setCurrentTableRow(row) {
		//   this.$refs.singleTable.setCurrentRow(row);
		// },

		// ========================================
		async changeTab(currentPageShow) {
			this.currentPage = currentPageShow;

			this.loading = true;
			let responseData = await apiClan.getClanInfoListByPage(
				this.totalRows,
				this.currentPage
			);
			this.loading = false;

			if (responseData.code == SERVER_RESPONSE_CODE.SUCCESS) {
				this.clanList = responseData.data;
				this.clanList.forEach((clanInfo) => {
					if (clanInfo.status === null || clanInfo.status === undefined) {
						clanInfo.status = CLAN_STATUS.UNKNOWN;
					}

					clanInfo.statusName = this.getKeyByValue(
						CLAN_STATUS,
						clanInfo.status
					);
				});
			} else {
				console.log(responseData);
			}
		},

		// ========================================
		setClanInfoFromServer(clanInfo) {
			if (clanInfo.status === null || clanInfo.status === undefined) {
				clanInfo.status = CLAN_STATUS.UNKNOWN;
			}

			clanInfo.statusName = this.getKeyByValue(CLAN_STATUS, clanInfo.status);

			this.currentClanInfo = { ...clanInfo };
		},

		// ========================================
		resetClanInfo() {
			this.currentClanInfo = { ...this.lastSelectedClanInfo };
			this.currentStatusOption = this.currentClanInfo.status;
		},

    getUserStatusStringFromInt(value) {
      switch (value) {
        case 1:
          return "NORMAL";
        case 2:
          return "CHEAT_WARNING";
        case 3:
          return "BLACK_LIST";
      }

      return "UNKNOWN";
    },

		// ========================================
		getMemberListFromDict(clanInfo) {
			let dictMemberUserIDJoinClan = clanInfo.dictMemberUserIDJoinClan;

			if (dictMemberUserIDJoinClan == null ||
				dictMemberUserIDJoinClan === undefined) {
				return [];
			}

			let returnList = [];
			let tempArray = Object.entries(dictMemberUserIDJoinClan);

			tempArray.forEach(([key, value]) => {
				let userID = value.userID;
				let tempMemberInfo = {};

				if (userID === clanInfo.leaderUserID) {
					tempMemberInfo = {
						value: value.userID,
						label: `${value.userID} (Leader)`,
					};
				} else if (clanInfo.listDeputyUserID.indexOf(userID) !== -1) {
					tempMemberInfo = {
						value: value.userID,
						label: `${value.userID} (Deputy)`,
					};
				} else {
					tempMemberInfo = {
						value: value.userID,
						label: `${value.userID}`,
					};
				}

				if (Object.hasOwn(this.clanMemberStatus, value.userID)) {
          tempMemberInfo.label = `${tempMemberInfo.label} -> ${this.getUserStatusStringFromInt(this.clanMemberStatus[value.userID].userStatus)}`
				}
				else {
          tempMemberInfo.label = `${tempMemberInfo.label} -> ${this.getUserStatusStringFromInt(0)}`
				}

				returnList.push(tempMemberInfo);
			});

			return returnList;
		},

		// ========================================
		getKeyByValue(object, value) {
			return Object.keys(object).find((key) => object[key] === value);
		},

		// ========================================
		handleCurrentTableChange(val) {
			this.currentClanInfo = { ...val };
			this.lastSelectedClanInfo = { ...val };

			//this.memberList = this.getMemberListFromDict(this.currentClanInfo);
			this.currentStatusOption = this.currentClanInfo.status;
		},

		async getClanMemberStatus() {
      if (this.currentClanInfo.clanID === undefined || this.currentClanInfo.clanID === null) {
        return;
      }

      this.loading = true;
      this.clanMemberStatus = {};
      try {

        let arrayId = [];
        for (let userId in this.currentClanInfo.dictMemberUserIDJoinClan) {
          arrayId.push(userId);
        }

        let arrayField = ["id", "userStatus"];

        let response = await mtoUserProfile.getArrayUserProfile(arrayId, arrayField);

        if (response.data === undefined || response.data === null) {
          Message({
            message: `${response.message}`,
            type: "error",
            duration: 5 * 1000,
          });

          return;
        }

        for (let i = 0; i < response.data.length; i++) {
          this.clanMemberStatus[response.data[i].id] = response.data[i];
        }
      }
      catch (ex) {
        Message({
          message: `Get member clan status error ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
      }

      this.loading = false;
		},

		settingMemberData() {
      this.deputiesMemberList = [];

      if (this.currentClanInfo === undefined || this.currentClanInfo === null) {
        return;
      }

      for (let userId in this.currentClanInfo.dictMemberUserIDJoinClan) {
        if (this.currentClanInfo.listDeputyUserID.indexOf(userId) === -1) {
          continue;
        }

        let addValue = {
          value: userId,
          label: `${userId} (Deputy)`,
        };

        if (Object.hasOwn(this.clanMemberStatus, userId)) {
          addValue.label = `${addValue.label} -> ${this.getUserStatusStringFromInt(this.clanMemberStatus[userId].userStatus)}`
        }
        else {
          addValue.label = `${addValue.label} -> ${this.getUserStatusStringFromInt(0)}`
        }

        this.deputiesMemberList.push(addValue);
      }

      this.memberList = this.getMemberListFromDict(this.currentClanInfo);
		},

		// ========================================
		// OTHERS METHOD
		async handleClick(tab, event) {
			if (tab.name === "clanlist") {
				this.currentClanInfo = {};
				this.lastSelectedClanInfo = {};
				await this.getClanInfoListByPage();
			}
			else if (tab.name === "claninfo") {
				await this.getClanMemberStatus();
				this.settingMemberData();
			}
		},
	},
};
</script>